<template>
  <section class="bg-light h-100 p-3">
    <div class="d-flex justify-content-between">
      <h2>Liste de categorie Article</h2>
      <button
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#modalAdd"
      >
        Ajouter
      </button>
    </div>
    <hr />
    <div>
      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th scope="col" class="text-center">#</th>
            <th scope="col" class="text-center">Identification</th>
            <th scope="col" class="text-center">Articles</th>
            <th scope="col" class="text-center" colspan="1">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id_categorie_article">
            <th scope="row" class="text-center">
              {{ item.id_categorie_article }}
            </th>
            <td class="text-center">{{ item.name_categorie_article }}</td>
            <td class="text-center">{{ item.NbArticle }}</td>
            <td class="text-center d-flex gap-2 justify-content-center">
              <div
                class="dropdown"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Modifier"
              >
                <div>
                  <IcoPen
                    data-bs-toggle="modal"
                    data-bs-target="#modalUpdate"
                    class="cursor-pointer"
                    @click="initUpdate(item)"
                  />
                </div>
              </div>
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Supprimer"
              >
                <IcoDelete
                  class="cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDelete"
                  @click="initDelete(item.id_categorie_article)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- modal add début-->
    <div
      class="modal fade"
      data-bs-backdrop="static"
      id="modalAdd"
      tabindex="-1"
      aria-labelledby="modalLabelAdd"
      aria-hidden="true"
    >
      <ModalAdd @fetch="fetch" />
    </div>
    <!-- modal add fin-->

    <!-- modal delete début-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalLabelDelete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              Êtes-vous sûr ?
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                La catégorie sera supprimé définitivement
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDelete"
            >
              Annuler
            </button>
            <button
              v-if="loading.delete"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button
              v-else
              @click="deleteCategorie()"
              class="btn btn-primary ms-3"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal delete fin-->
    <!-- modal update début-->
    <div
      class="modal fade"
      id="modalUpdate"
      tabindex="-1"
      aria-labelledby="modalLabelmodalUpdate"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <form
          @submit.prevent="confirmUpdate"
          class="modal-content border-0 bg-light text-dark"
        >
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelAjout">
              Modifier la catégorie
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12 mb-3">
                <label for="inputMail1" class="form-label"
                  >Identification</label
                >
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="dataUpdate.name_categorie_article"
                />
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="close"
            >
              Annuler
            </button>
            <button
              v-if="loading.update"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button v-else type="submit" class="btn btn-primary ms-3">
              Confirmer
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- modal update fin-->
  </section>
</template>

<script>
import IcoPen from "vue-material-design-icons/Pen.vue";
import IcoDelete from "vue-material-design-icons/Delete.vue";
import {
  getAllCategoryArticle,
  deleteCategoryArticle,
  updateCategoryArticle,
} from "../../api/categorieArticle";
import ModalAdd from "../../components/admin/ModalNewCategorieArticle.vue";
import { success, error } from "../../utils/toast";

export default {
  name: "CategorieArticle",
  components: {
    IcoPen,
    IcoDelete,
    ModalAdd,
  },
  data() {
    return {
      data: [],
      idDelete: 0,
      dataUpdate: {},
      loading: {
        data: false,
        update: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getAllCategoryArticle().then((result) => {
        this.loading.data = false;
        this.data = result.data;
      });
    },
    initDelete(id) {
      this.idDelete = id;
    },
    initUpdate(data) {
      this.dataUpdate = { ...data };
    },
    confirmUpdate() {
      this.loading.update = true;
      updateCategoryArticle(this.dataUpdate).then((result) => {
        if (result.data.error) {
          this.loading.update = false;
          error(result.data.error, "");
        } else {
          this.loading.update = false;
          this.$refs.close.click();
          success("Catégorie modifié");
          this.fetch();
        }
      });
    },
    deleteCategorie() {
      this.loading.delete = true;
      deleteCategoryArticle(this.idDelete).then(() => {
        this.fetch();
        this.loading.delete = false;
        this.$refs.CloseDelete.click();
        success("Catégorie supprimé");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  cursor: pointer;
}
</style>
